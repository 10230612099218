/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
		// JavaScript to be fired on all pages 
	
      }, 
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
 			// Mainstream Maps © Giant Training 2016
			var markers = [];
			var infoWindows = [];
			function drop(map, myLatLng) {
				setTimeout(function() {
		            var marker = new google.maps.Marker({
		              position: myLatLng,
		              map: map,
		              animation: google.maps.Animation.DROP
		            });
		    	}, i * 500);
			 }
			var currentId = 0;
			var currentIdt = 0;
			var uniqueId = function() {
			    return ++currentId;
			}
			var uniqueIdt = function() {
			    return ++currentIdt;
			}
			function new_map( $el ){
				var $markers = $el.find('.marker');
				var args = {
					zoom : 16,
					center : new google.maps.LatLng(0, 0),
					mapTypeId : google.maps.MapTypeId.ROADMAP
				};     	
				var map = new google.maps.Map( $el[0], args);
				map.markers = [];
				$markers.each(function(){ 
					var id = uniqueId();
					$(this).attr("data-markerid", id-1);
			    	add_marker( $(this), map );
				});
				center_map( map ); 
				return map;
			}
			function add_marker( $marker, map ) {
				var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
				var image = new google.maps.MarkerImage( $marker.attr('data-icon'),
				    new google.maps.Size(16, 15),
				  	new google.maps.Point(0,0),
				 	new google.maps.Point(8, 7)
				 );
				var marker = new google.maps.Marker({
					position : latlng,
					map	: map,
					icon : image,
					animation : google.maps.Animation.DROP
				});
				markers.push(marker);
				map.markers.push( marker );
				if( $marker.html() ){
					var infowindow = new google.maps.InfoWindow({
						content	: $marker.html(),
						maxWidth: 350
					});
					infoWindows.push(infowindow); 
					// open click event
					google.maps.event.addListener(marker, 'click', function() {
						closeAllInfoWindows();
						infowindow.open( map, marker );
						map.setZoom(6);
						$('.overlays').hide();
						map.setCenter(marker.getPosition());
						$(".gm-style-iw").parent().addClass("iw");
						doBounce($(".iw"), 2, "30px", 300);
					});
					// close click event
					google.maps.event.addListener(infowindow, "closeclick", function(){
					   center_map(map);
					   $('.overlays').show();
					  
					});
				}
			}
			function closeAllInfoWindows() {
			  for (var i=0;i<infoWindows.length;i++) {
			     infoWindows[i].close();
			  }
			}
			function doBounce(element, times, distance, speed) {
			    for(i = 0; i < times; i++) {
			        element.animate({marginTop: '-='+distance},speed).animate({marginTop: '+='+distance},speed);
			    }        
			}
			function center_map( map ) {
				var bounds = new google.maps.LatLngBounds();
				$.each( map.markers, function( i, marker ){
					var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
					bounds.extend( latlng );
				});
				if( map.markers.length === 1 ){
				    map.setCenter( bounds.getCenter() );
				    map.setZoom( 15 );
				}else{
					map.fitBounds( bounds );
				}
			}
			var map = null;
			$(document).ready(function(){	
				$('.marker-link').each(function(){
					var id = uniqueId();
					$(this).attr("data-markerid", id-1);
				});
				$('.marker-link-table').each(function(){
					var idt = uniqueIdt();
					$(this).attr("data-markerid", idt-1);
				});
				$('.map').each(function(){
					map = new_map( $(this) );
				});
				$('.marker-link').on('click', function () {
					closeAllInfoWindows();
					$('.overlays').hide();
			        google.maps.event.trigger(markers[$(this).data('markerid')], 'click'); 
					var mpos = $(this).parent().parent().parent().parent().next().attr("id");
					jQuery("html, body").animate({ scrollTop: $('#'+mpos).offset().top-150 }, 500); 
			    });
				$('.marker-link-table').on('click', function () {
					closeAllInfoWindows();
					$('.overlays').hide();
			        google.maps.event.trigger(markers[$(this).data('markerid')], 'click');
					var mtpos = $(this).parent().parent().parent().parent().parent().parent().parent().parent().prev().attr("id");
					jQuery("html, body").animate({ scrollTop: $('#'+mtpos).offset().top-150 }, 500); 
			    });
			});
			
			$('.arrow_box').click(function() {
				var arrowTop = $(this).position().top + 15;
				$('.rarrow').animate({top: arrowTop + 'px'}, 750);
				$('html,body').animate({
				   scrollTop: $(".tab-content").offset().top - 110
				});
			});
			
			$('.translation-links a').click(function() {
		      var lang = $(this).data('lang');
		      var $frame = $('.goog-te-menu-frame:first');
		      if (!$frame.size()) {
		        alert("Error: Could not find Google translate frame.");
		        return false;
		      }
		      $frame.contents().find('.goog-te-menu2-item span.text:contains('+lang+')').get(0).click();
		      return false;
		    });
			
			$(".dropdown-toggle").dropdown();
		
			$('.onshore-wind:last').attr('class', 'col-xs-12 col-md-12 locations onshore-wind mb');
			$('.offshore-wind:last').attr('class', 'col-xs-12 col-md-12 locations offshore-wind mb');
			$('.solar:last').attr('class', 'col-xs-12 col-md-12 locations solar mb');
			$('.wind:last').attr('class', 'col-xs-12 col-md-12 locations wind mb');  
			/*
			// Grayscale w canvas method
			function grayscale(src){
				var canvas = document.createElement('canvas');
				var ctx = canvas.getContext('2d');
				var imgObj = new Image();
				imgObj.src = src;
				canvas.width = imgObj.width;
				canvas.height = imgObj.height; 
				ctx.drawImage(imgObj, 0, 0); 
				var imgPixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
				for(var y = 0; y < imgPixels.height; y++){
					for(var x = 0; x < imgPixels.width; x++){
						var i = (y * 4) * imgPixels.width + x * 4;
						var avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
						imgPixels.data[i] = avg; 
						imgPixels.data[i + 1] = avg; 
						imgPixels.data[i + 2] = avg;
					}
				}
				ctx.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);
				return canvas.toDataURL();
		    }
		
			$(".director-img").fadeIn(500);

			// clone image
			$('.director-img').each(function(){
		
				var el = $(this);
				el.css({"position":"relative"}).wrap("<div class='img_wrapper' style='display: inline-block'>").clone().addClass('img_grayscale').css({"position":"absolute","z-index":"998","opacity":"0"}).insertBefore(el).queue(function(){
					var el = $(this);
					el.parent().css({"width":this.width,"height":this.height});
					el.dequeue();
				});
				this.src = grayscale(this.src);	
			
			});
			*/

		
			var active = true;
			//$('#accordion .in').prev().find("img:first").stop().animate({opacity:1}, 100);
			$('#accordion')
			  .on('show.bs.collapse', function(e) {
				if (active){ 
					$('#accordion .in').collapse('hide'); 
				}
				$(e.target).prev('.panel-heading').find('img').removeClass('desaturate');
			  })
			  .on('hide.bs.collapse', function(e) {
			    $(e.target).prev('.panel-heading').find('img').addClass('desaturate');
				//$(e.target).prev('.panel-heading').removeClass('active');
			  });
	

			/*
			$( ".j-block" ).click(function() {
				if ($(this).parent().find('img:first').css('opacity') == 1) {
			        $(this).parent().find('img:first').stop().animate({opacity:0}, 1000);
			    } else {
			        $(this).parent().find('img:first').stop().animate({opacity:1}, 1000);
			    }
			});
			
			$( ".director-img" ).click(function() {
				if ($(this).parent().find('img:first').css('opacity') == 1) {
			        $(this).parent().find('img:first').stop().animate({opacity:0}, 1000);
			    } else {
			        $(this).parent().find('img:first').stop().animate({opacity:1}, 1000);
			    }
			});
			*/
			// Fade image 
			/*
			$('.j-block').mouseover(function(){
				if(!$(this).parent().parent().hasClass(".active")){
					$(this).parent().find('img:first').stop().animate({opacity:1}, 1000);
				}
					
			})
			$('.j-block').mouseout(function(){
				if(!$(this).parent().parent().hasClass(".active")){
					$(this).parent().find('img:first').stop().animate({opacity:0}, 1000);
				}
			});
			*/
			
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

